import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../layouts'

import './index.css';

export default ({ data }) => {
  const { markdownRemark: page } = data;
  return (
    <Layout>
      <Helmet title={`Evolve | ${page.frontmatter.title}`} />
      <header>
        <h1>{page.frontmatter.title}</h1>
        <span>{page.frontmatter.baseline}</span>
      </header>
      <div dangerouslySetInnerHTML={{ __html: page.html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query DocsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      id
      frontmatter {
        path
        title
      }
    }
  }
`;
